// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

// Highlighting for code blocks
import "prismjs/themes/prism.css"
import "prismjs/prism"
import "prismjs/components/prism-sql"
import "prismjs/components/prism-bash"
import "prismjs/components/prism-javascript"
import "prismjs/components/prism-json"
import "prismjs/components/prism-jsx"
import "prismjs/components/prism-markdown"
import "prismjs/components/prism-tsx"
import "prismjs/components/prism-typescript"
import "prismjs/components/prism-yaml"
import "prismjs/components/prism-css"

// custom CSS styles
import "./src/style.css"

// Wrap the require in check for window
if (typeof window !== "undefined") {
  require("prismjs/plugins/line-numbers/prism-line-numbers.css")
  require("prismjs/plugins/command-line/prism-command-line.css")
}

// This ensures Prism is loaded before it's used
export const onClientEntry = () => {
  window.Prism = window.Prism || {}
  window.Prism.manual = true
}

// Highlight code blocks after the page has loaded
export const onRouteUpdate = () => {
  if (typeof window !== "undefined" && typeof window.Prism !== "undefined") {
    window.Prism.highlightAll()
  }
}
